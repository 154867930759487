import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "ionicons/dist/css/ionicons.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from "./serviceWorker";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
