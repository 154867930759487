import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Link } from "react-router-dom"
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import FollowerReqIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationsIcon from '@mui/icons-material/Notifications';
import BackpackIcon from '@mui/icons-material/Backpack';
import CategoryIcon from '@mui/icons-material/Category';
import { useLocation } from 'react-router-dom'
import defaultImg from "../assets/images/default.jpg";
import { connect } from "react-redux"
import * as authActions from "../store/actions/auth"
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CachedIcon from '@mui/icons-material/Cached';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import "./slide.css"
import { useHistory } from "react-router-dom";
import SettingsIcon from '@mui/icons-material/Settings';
import CampaignIcon from '@mui/icons-material/Campaign';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import PublicIcon from '@mui/icons-material/Public';
import RefreshIcon from '@mui/icons-material/Refresh';
import LogoImage from "../assets/images/newLogo.svg"
// import LogoImage from "../assets/images/logo.svg"

import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CalculateIcon from '@mui/icons-material/Calculate';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PercentIcon from '@mui/icons-material/Percent';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MoneyIcon from '@mui/icons-material/Money';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PaidIcon from '@mui/icons-material/Paid';
import StarsIcon from '@mui/icons-material/Stars';
import FeaturedPlayListIcon from '@mui/icons-material/FeaturedPlayList';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import SchemaIcon from '@mui/icons-material/Schema';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import HomeIcon from '@mui/icons-material/Home';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddCardIcon from '@mui/icons-material/AddCard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
const drawerWidth = 240;
function ResponsiveDrawer({ window, logOut, children, user, refresh }) {
  const history = useHistory()
  const [selected, setSelected] = React.useState("")
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [menuName, setMenuName] = React.useState(false);

  const [open1, setOpen1] = React.useState({});
  const location = useLocation();
  const path = location.pathname
  React.useEffect(() => {
    const finalPath = path.slice(1, path.length).slice(0, 4)
    setSelected(finalPath)

  }, [path])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };




  const lists = [
    {
      key: "dash",
      label: "Dashboard",
      icon: DashboardIcon,
      route: "/dashboard"
    },
    {
      key: "crm",
      label: "CRM",
      icon: ManageAccountsIcon,
      items: [
        { key: "customer", label: "Shoppers", icon: PeopleAltIcon, fr: "cust" },
        { key: "accountMain", label: "Influencers", icon: CampaignIcon, fr: "acco" },
        { key: "brand", label: "Brands", icon: BrandingWatermarkIcon, fr: "bran" },
      ]
    },
    {
      key: "productManagement",
      label: "Product Management",
      icon: ManageSearchIcon,
      items: [
        { key: "packages", label: "Packages", icon: BackpackIcon, fr: "pack" },
        // { key: "categories", label: "Categories", icon: CategoryIcon, fr: "cate" },
        { key: "newcategories", label: "Categories", icon: CategoryIcon, fr: "newc" },
        { key: "subcategories", label: "Sub Categories", icon: SchemaIcon, fr: "subc" },
        { key: "notification", label: "Notifications", icon: NotificationsIcon, fr: "noti" },
        { key: "promocodes", label: "Promocodes", icon: ConfirmationNumberIcon, fr: "prom" },
        { key: "country", label: "Country", icon: PublicIcon, fr: "coun" },
        { key: "userCategories", label: "Custom Categories", icon: SettingsIcon, fr: "user" },
      ]
    },
    {
      key: "accounting",
      label: "Accounting",
      icon: CalculateIcon,
      items: [
        // { key: "transaction", label: "Transaction", icon: LibraryBooksIcon, fr: "tran" },
        { key: "deposit", label: "Deposit", icon: AddCardIcon, fr: "depo" },
        { key: "sales", label: "Sales", icon: PaidIcon, fr: "sale" },
        { key: "retSakes", label: "Sales Return", icon: RestartAltIcon, fr: "retS" },
        { key: "purchase", label: "Purchases", icon: ShoppingCartIcon, fr: "purc" },
        { key: "retPurchase", label: "Purchase Return", icon: CurrencyExchangeIcon, fr: "retP" },
        { key: "cashback", label: "Cashback", icon: LocalAtmIcon, fr: "cash" },
        { key: "commission", label: "Commission", icon: AttachMoneyIcon, fr: "comm" },
        { key: "withdrawal", label: "Withdrawal", icon: AccountBalanceWalletIcon, fr: "with" },
        // { key: "ledger", label: "Ledger", icon: LibraryBooksIcon, fr: "ledg" },
        { key: "kbcommission", label: "KB Commission", icon: PercentIcon, fr: "kbco" },
        { key: "kbearning", label: "KB Earning", icon: MoneyIcon, fr: "kbea" },
        { key: "kbbalance", label: "KB Balance", icon: PaidIcon, fr: "kbba" },
      ]
    },
    // {
    //   key: "marketing",
    //   label: "Marketing",
    //   icon: CampaignIcon,
    //   items: [
    //     { key: "topBrand", label: "Top Brand", icon: BrandingWatermarkIcon, fr: "topB" },
    //     { key: "topInfluencer", label: "Top Influencer", icon: CampaignIcon, fr: "topI" },
    //     { key: "topCustomer", label: "Top Customer", icon: PeopleAltIcon, fr: "topC" },
    //   ]
    // },
    {
      key: "sale",
      label: "Sales",
      icon: PaidIcon,
      items: [
        { key: "bSale", label: "Brand Sales", icon: LocalAtmIcon, fr: "bSal" },
        { key: "iSale", label: "Influencer Sales", icon: AttachMoneyIcon, fr: "iSal" },
        { key: "cSale", label: "Customer Sales", icon: PaidOutlinedIcon, fr: "cSal" },
      ]
    },
    // {
    //   key: "adManage",
    //   label: "Ad Manage",
    //   icon: ManageHistoryIcon,
    //   items: [
    //     { key: "adManage", label: "Home Page", icon: HomeIcon, fr: "adMa" },
    //     // { key: "adManage", label: "Home Page", icon: HomeIcon, fr: "adMa" },
    //   ]
    // }
    {
      key: "orde",
      label: "Order Details",
      icon: ShoppingBasketIcon,
      route: "/orders"
    },
    {
      key: "feat",
      label: "Brand Featured",
      icon: FeaturedPlayListIcon,
      route: "/featured"
    },
    {
      key: "catF",
      label: "Category Featured",
      icon: CategoryIcon,
      route: "/catFeatured"
    },
    {
      key: "catP",
      label: "Popular Category",
      icon: StarsIcon,
      route: "/catPopular"
    },
    {
      key: "foll",
      label: "Influencer Requests",
      icon: FollowerReqIcon,
      route: "/followersRequests"
    },
    {
      key: "adMa",
      label: "Ad Manager",
      icon: ManageHistoryIcon,
      route: "/adManager"
    },
    {
      key: "adPr",
      label: "Ad Price Manager",
      icon: ManageHistoryIcon,
      route: "/adPrice"
    },
    {
      key: "mana",
      label: "Access",
      icon: LockIcon,
      route: "/manageAccess"
    },
    {
      key: "logs",
      label: "Logs",
      icon: DescriptionIcon,
      route: "/logs"
    },
    {
      key: "camp",
      label: "Brand-Campaign",
      icon: BrandingWatermarkIcon,
      route: "/campaign"
    },
    {
      key: "infl",
      label: "Influencer-Campaign",
      icon: CampaignIcon,
      route: "/influencer"
    },
    {
      key: "appS",
      label: "App Setting",
      icon: AppSettingsAltIcon,
      route: "/appSetting"
    },
    {
      key: "auth",
      label: "Auth Permissions",
      icon: EnhancedEncryptionIcon,
      route: "/authPer"
    },
    {
      key: "adm",
      label: "Administrator",
      icon: AdminPanelSettingsIcon,
      items: [
        { key: "users", label: "Users", icon: PersonAddIcon, fr: "user" },
      ]
    },
  ];
  const click = (key) => {
    setOpen1({ [key]: !open1[key] })
  }
  const drawer = (
    <div>
      <div className='admin-profile-box'>
        <img className='admin-profile-pic'
          src={defaultImg}
        />
        <h6>{user.message?.name}</h6>
        <p>{user.message?.email}</p>
      </div>

      <List className='dashboard-menu dash-menu-main'>
        {/* <Link className="link" to="/dashboard">
          <ListItem
            className='mylist'
            onClick={() => {
              setMobileOpen(false)
              setSelected("dash")
            }}
            selected={selected === "dash" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <DashboardIcon color={selected === "dash" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {
          lists.map(({ key, label, icon: Icon, items, route }) => {
            const open = open1[key] || false;
            return (
              <div key={key}>
                {/* {console.log('item', items)} */}
                {!items ?
                  <>
                    <Link className="link" to={route}>
                      <ListItem
                        className='mylist'
                        onClick={() => {
                          setMobileOpen(false)
                          click(key)
                          setSelected(key)
                        }}
                        selected={open === "dash" ? true : false} disablePadding
                      >
                        <ListItemButton className='dash-menu-button'>
                          <ListItemIcon className='dash-menu-icon'>
                            <Icon color={open ? "primary" : "inherit"} />
                          </ListItemIcon>
                          <ListItemText primary={label} className='dash-menu-txt' />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  </>
                  :
                  <>
                    <ListItem
                      onClick={() => {
                        click(key)
                      }}
                      disablePadding>
                      <ListItemButton className='dash-menu-button' >
                        <ListItemIcon className='dash-menu-icon'>
                          <Icon color={open ? "primary" : "inherit"} />
                        </ListItemIcon>
                        <ListItemText
                          className='dash-menu-txt'
                          primary={label}
                        />
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </ListItem>
                    <Collapse className='drop-main' in={open} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {items.map(({ key: childKey, label: childLabel, icon: ChildIcon, fr: ChildFr }) => (
                          <Link key={childKey} className='link' to={`/${childKey}`}>
                            <ListItem
                              onClick={() => {
                                setMobileOpen(false)
                              }}
                              key={childKey}
                              selected={selected === ChildFr ? true : false}
                              disablePadding
                            >
                              <ListItemButton sx={{ pl: 4 }} className='dash-menu-button second-level'>
                                <ListItemIcon className={key === "sale" ? " dash-menu-icon check" : "dash-menu-icon"}>
                                  <ChildIcon color={selected === ChildFr ? "primary" : "inherit"} />
                                </ListItemIcon>
                                <ListItemText primary={childLabel} className='dash-menu-txt' />
                              </ListItemButton>
                            </ListItem>
                          </Link>
                        ))}
                      </List>
                    </Collapse>
                  </>
                }
              </div>
            )
          })
        }
        {/* <Link className="link" to="/featured">
          <ListItem
            className='mylist'
            onClick={() => {
              setMobileOpen(false)
              setSelected("feat")
            }}
            selected={selected === "feat" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <FeaturedPlayListIcon color={selected === "feat" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Brand Featured" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link className="link" to="/catFeatured">
          <ListItem
            className='mylist'
            onClick={() => {
              setMobileOpen(false)
              setSelected("catF")
            }}
            selected={selected === "catF" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <CategoryIcon color={selected === "catF" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Category Featured" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link className="link" to="/catPopular">
          <ListItem
            className='mylist'
            onClick={() => {
              setMobileOpen(false)
              setSelected("catP")
            }}
            selected={selected === "catP" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <StarsIcon color={selected === "catP" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Popular Category" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link className="link" to="/followersRequests">
          <ListItem
            onClick={() => {
              setMobileOpen(false)
              setSelected("foll")
            }}
            selected={selected === "foll" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <FollowerReqIcon color={selected === "foll" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Influencer Requests" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}

        {/* <Link className="link" to="/adManager">
          <ListItem
            onClick={() => {
              setMobileOpen(false)
              setSelected("adMa")
            }}
            selected={selected === "adMa" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <ManageHistoryIcon color={selected === "adMa" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Ad Manager" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link className="link" to="/adPrice">
          <ListItem
            onClick={() => {
              setMobileOpen(false)
              setSelected("adPr")
            }}
            selected={selected === "adPr" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <ManageHistoryIcon color={selected === "adPr" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Ad Price Manager" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link className="link" to="/manageAccess">
          <ListItem
            onClick={() => {
              setMobileOpen(false)
              setSelected("mana")
            }}
            selected={selected === "mana" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <LockIcon color={selected === "mana" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Access" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link className="link" to="/logs">
          <ListItem
            onClick={() => {
              setMobileOpen(false)
              setSelected("logs")
            }}
            selected={selected === "logs" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <DescriptionIcon color={selected === "logs" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Logs" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}



        {/* <Link className="link" to="/campaign">
          <ListItem
            onClick={() => {
              setMobileOpen(false)
              setSelected("camp")
            }}
            selected={selected === "camp" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <BrandingWatermarkIcon color={selected === "camp" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Brand-Campaign" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link className="link" to="/influencer">
          <ListItem
            onClick={() => {
              setMobileOpen(false)
              setSelected("infl")
            }}
            selected={selected === "infl" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <CampaignIcon className='Campaign-icon' color={selected === "infl" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Influencer-Campaign" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link className="link" to="/appSetting">
          <ListItem
            onClick={() => {
              setMobileOpen(false)
              setSelected("appS")
            }}
            selected={selected === "appS" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <AppSettingsAltIcon color={selected === "appS" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="App Setting" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
        {/* <Link className="link" to="/authPer">
          <ListItem
            onClick={() => {
              setMobileOpen(false)
              setSelected("auth")
            }}
            selected={selected === "auth" ? true : false} disablePadding>
            <ListItemButton className='dash-menu-button'>
              <ListItemIcon className='dash-menu-icon'>
                <EnhancedEncryptionIcon color={selected === "auth" ? "primary" : "inherit"} />
              </ListItemIcon>
              <ListItemText primary="Auth Permissions" className='dash-menu-txt' />
            </ListItemButton>
          </ListItem>
        </Link> */}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f7f7f7', height: '100%' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: 'white'
        }}
      >
        <Toolbar>
          <IconButton
            className='hamburger-btn'
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/" className="logo">
            <img
              alt="logo"
              src={LogoImage}
              className="logo-image"
            //className="img-fluid"
            />
          </Link>
          <Box className='icon-style-for-mob' sx={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
            {
              selected == "dash" ? (
                <Button
                  onClick={refresh}
                  endIcon={<RefreshIcon />}
                  className="btn-width btn-gen-margin button-icon-small" color="success" variant="contained" disableElevation><span className='d-none d-sm-block'>Refresh</span></Button>
              ) : null
            }
            <Button
              onClick={() => {
                logOut()
                history.push('/login')
              }}
              endIcon={<LogoutIcon />}
              className="btn-width btn-gen-margin mr-0 button-icon-small" color="primary" variant="contained" disableElevation><span className='d-none d-sm-block'>Log Out</span></Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          className='sidebar-main'
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: selected === "acco" ? 0 : 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function mapStateToProps({ user }) {
  return { user }
}

export default connect(mapStateToProps, authActions)(ResponsiveDrawer);