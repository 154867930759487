import { SUB_CAT } from "../../actions/type";

const initialState = [];

export default function subcategories(state = initialState, action) {
    switch (action.type) {
        case SUB_CAT:
            return action.payload;
        default:
            return state
    }
}