import React, { Component, useEffect, useState, lazy, Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import Login from "./auth/LoginComponent";
import SkeletonLoader from "../reUsable/skeleton"
import DrawerHeader from "./drawer"
import * as authActions from "../store/actions/auth"
import { toast } from "react-toastify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const DashboardComponent = lazy(() => import("./dashboard/DashboardComponent"))
const Ledger = lazy(() => import("./ledger/Ledger"))
const Transaction = lazy(() => import("./transaction/Transaction"))
const AccountDetail = lazy(() => import("./accountMain/AccountDetail"))
const Packages = lazy(() => import("./package/packages"))
const Access = lazy(() => import("./manageAccess/Access"))
const AddPackage = lazy(() => import("./package/AddPackage"))
const PackageDetail = lazy(() => import("./package/PackageDetail"))
const EditPackage = lazy(() => import("./package/EditPackage"))
const Logs = lazy(() => import("./logs/logs"))
const CreatePromoCode = lazy(() => import("./code/CreatePromoCode"))
const Promocodes = lazy(() => import("./code/promoCodes"))
const EditPromo = lazy(() => import("./code/EditPromo"))
const AccountsInfluencer = lazy(() => import("./accountMain/AccountsInfluencer"))
const Subscribtion = lazy(() => import("./subscribtion/Subscribtion"))
const UserCategories = lazy(() => import("./userCategory/UserCategories"))
const BrandCampaign = lazy(() => import("./campaign/Brand"))
const Campaign = lazy(() => import("./campaign/campaigns/campains"))
const AuthPermission = lazy(() => import("./authPer/AuthPermission"))
const Country = lazy(() => import("./country/Country"))
const EditCampaign = lazy(() => import("./campaign/EditCampaign"))
const Influencer = lazy(() => import("./influencerCampaign/Influencer"))
const InfluencerCampaign = lazy(() => import("./influencerCampaign/campaigns/campains"))
const Notification = lazy(() => import("./notification/Notification"))
const FollowersRequests = lazy(() => import("./followersRequest"))
const AccountsBrand = lazy(() => import("./accountMain/AccountBrand"))
const AccountsCustomer = lazy(() => import("./accountMain/AccountCustomer"))
const KBCommission = lazy(() => import("./KB/KBCommission"))
const KBBalance = lazy(() => import("./KB/KBBalance"))
const BrandFeatured = lazy(() => import("./BrandFeatured/BrandFeatured"))
const Sales = lazy(() => import("./sales/Sales"))
const AccountsSales = lazy(() => import("./accounting/SalesAccounting"))
const CategoryPopular = lazy(() => import("./categoryPopular/CategoryPopular"))
const AdManage = lazy(() => import("./adManage/AdManage"))
const FeaturedCategory = lazy(() => import("./categoryFeatured/FeaturedCategory"))
const Top = lazy(() => import("./marketing/Top"))
const AppSetting = lazy(() => import("./appSetting/AppSetting"))
const SubCategory = lazy(() => import("./subCategory/SubCategory"))
const NewCategory = lazy(() => import("./newCategory/Categories"))
const AdPrice = lazy(() => import("./adPriceTable/AdPrice"))
const NotMatch = lazy(() => import("./NotMatch"))
const Commission = lazy(() => import("./accounting/Commission"))
const Cashback = lazy(() => import("./accounting/Cashback"))
const Purchase = lazy(() => import("./accounting/Purchase"))
const Withdrawal = lazy(() => import("./accounting/Withdrawal"))
const Deposit = lazy(() => import("./accounting/Deposit"))
const User = lazy(() => import("./users/User"))
const CreateUser = lazy(() => import("./users/CreateUser"))
const EditUser = lazy(() => import("./users/EditUser"))
const Orders = lazy(() => import("./orders/Orders"))
function Main({ setUser, logOut, user }) {
    const [loading, setLoading] = useState(true)
    var func
    function showMessage() {
        toast.info("Session Expire")
    }
    useEffect(() => {
        setUser(logOut, () => setLoading(false), showMessage)
    }, [])

    function refreshMain(cb) {
        func = cb
    }

    if (!loading) {
        if (user?.success) {
            return (
                <>
                    <DrawerHeader refresh={() => func()}>
                        <Suspense fallback={<MainLoad />}>
                            <Switch>
                                <Route exact path="/" component={() => <Redirect to="/dashboard" />} />
                                <Route exact path="/login" component={() => <Redirect to="/dashboard" />} />
                                <Route exact path="/dashboard" component={() => <DashboardComponent refresh={refreshMain} />} />
                                <Route exact path="/accountMain" component={AccountsInfluencer} />
                                <Route exact path="/customer" component={AccountsCustomer} />
                                <Route exact path="/brand" component={AccountsBrand} />
                                <Route path="/accountMain/detail/:id" component={AccountDetail} />
                                <Route exact path="/packages" component={Packages} />
                                <Route exact path="/packages/detail/:id" component={PackageDetail} />
                                <Route exact path="/packages/edit/:id" component={EditPackage} />
                                <Route exact path="/packages/add" component={AddPackage} />
                                <Route exact path="/manageAccess" component={Access} />
                                <Route exact path="/logs" component={Logs} />
                                <Route exact path="/notification" component={Notification} />
                                <Route exact path="/promocodes" component={Promocodes} />
                                <Route exact path='/promocodes/create' component={CreatePromoCode} />
                                <Route exact path='/promocodes/edit/:id' component={EditPromo} />
                                <Route exact path='/subscribtion' component={Subscribtion} />
                                <Route exact path='/userCategories' component={UserCategories} />
                                <Route exact path='/campaign' component={BrandCampaign} />
                                <Route exact path='/campaign/:id' component={Campaign} />
                                <Route exact path='/influencer' component={Influencer} />
                                <Route exact path='/influencerCampaign/:id' component={InfluencerCampaign} />
                                <Route exact path='/authPer' component={AuthPermission} />
                                <Route exact path='/country' component={Country} />
                                <Route exact path='/updateCampaign/:id' component={EditCampaign} />
                                <Route exact path="/followersRequests" component={FollowersRequests} />
                                <Route exact path="/ledger" component={Ledger} />
                                <Route exact path="/kbcommission" component={() => <KBCommission type='Commission' />} />
                                <Route exact path="/kbearning" component={() => <KBCommission type='Earning' />} />
                                <Route exact path="/kbbalance" component={KBBalance} />
                                <Route exact path="/featured" component={BrandFeatured} />
                                <Route exact path="/bSale" component={() => <Sales type="Brand" />} />
                                <Route exact path="/iSale" component={() => <Sales type="Influencer" />} />
                                <Route exact path="/cSale" component={() => <Sales type="Customer" />} />
                                <Route exact path="/catPopular" component={CategoryPopular} />
                                <Route exact path="/catFeatured" component={FeaturedCategory} />
                                {/* <Route exact path="/topBrand" component={() => <Top type="brand" />} />
                                <Route exact path="/topInfluencer" component={() => <Top type="influencer" />} />
                                <Route exact path="/topCustomer" component={() => <Top type="customer" />} /> */}
                                <Route exact path="/appSetting" component={AppSetting} />
                                <Route exact path="/newcategories" component={NewCategory} />
                                <Route exact path="/subcategories" component={SubCategory} />
                                <Route exact path="/adManager" component={AdManage} />
                                <Route exact path="/adPrice" component={AdPrice} />
                                <Route exact path="/sales" component={() => <AccountsSales type="Brand" from="accounting" />} />
                                <Route exact path="/purchase" component={() => <Purchase type="Brand" from="accounting" />} />
                                <Route exact path="/commission" component={Commission} />
                                <Route exact path="/cashback" component={Cashback} />
                                <Route exact path="/withdrawal" component={Withdrawal} />
                                <Route exact path="/deposit" component={Deposit} />
                                <Route exact path="/users" component={User} />
                                <Route exact path='/users/create' component={CreateUser} />
                                <Route exact path='/users/edit/:id' component={EditUser} />
                                <Route exact path='/orders' component={Orders} />

                                <Route component={NotMatch} />
                            </Switch>
                        </Suspense>
                    </DrawerHeader>
                </>
            )
        } else {
            return (
                <>
                    <Route path="/" component={() => <Redirect to="/login" />} />
                    <Route exact path="/login" component={Login} />
                </>
            )
        }
    } else {
        return <SkeletonLoader />
    }

}

const mapStateToProps = ({ user }) => {
    return { user };
};

export default connect(mapStateToProps, authActions)(Main);

const MainLoad = () => {
    return (
        <div className="mainLoad">
            <SkeletonTheme color="#f0f1f2" highlightColor="#f8f9f9">
                <Skeleton height={50} count={16} />
            </SkeletonTheme>
        </div>
    )
}